import { Provider } from "./components/ui/provider";

import "./App.css";
import { Demo } from "./components/ui/demo";

function App() {
  return (
    <Provider>
      <Demo />
    </Provider>
  );
}

export default App;
