import { Button } from "./button";
import { HStack, Heading } from "@chakra-ui/react";
import CONSTANTS from "../../util/constants.js";
import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import { useEffect, useState } from "react";
import api from "../../api.js";

export const Demo = () => {
  const [username, setUsername] = useState("n/a");
  const [discordId, setDiscordId] = useState(null);

  function getUser() {
    api
      .get("/v1/user/me")
      .then((val) => {
        setUsername(val.data.username);
        setDiscordId(val.data.discordId);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <HStack>
      <Heading>{username}</Heading>
      {(() => {
        if (discordId)
          return (
            <Button
              onClick={() => {
                window.open(`${CONSTANTS.SERVER_URL}/v1/auth/logout`);
              }}
            >
              Log out
            </Button>
          );
        else
          return (
            <Button
              onClick={() => {
                window.open(`${CONSTANTS.SERVER_URL}/v1/auth/discord`);
              }}
            >
              Log in
            </Button>
          );
      })()}
      {(() => {
        if (discordId)
          return (
            <>
              <Button
                onClick={() => {
                  api.post(`/v1/playback/pause`);
                }}
              >
                {"Pause"}
              </Button>
              <Button
                onClick={() => {
                  api.post(`/v1/playback/resume`);
                }}
              >
                {"Resume"}
              </Button>
            </>
          );
      })()}
      <DialogRoot>
        <DialogTrigger asChild>
          <Button variant="outline" size="sm">
            Open Dialog
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Dialog Title</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </DialogBody>
          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button variant="outline">Cancel</Button>
            </DialogActionTrigger>
            <Button>Save</Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </HStack>
  );
};
